import React from 'react';
import { graphql } from 'gatsby';
import { SiteLayout, Head, Media } from '~/components';
import * as styles from "./news.module.scss";

const NewsPage = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title;
  const news = data.news.nodes;

  return (
    <SiteLayout title={siteTitle}>
      <Head title="お知らせ" />

      <div className={styles.inner}>
        <h1>お知らせ</h1>
      </div>

      <div className={styles.medias}>
        {news.map(value => (
          <Media
            key={`news_${value.date}`}
            title={value.title}
            html={value.content}
            date={value.date}
          />
        ))}
      </div>

    </SiteLayout>
  )
}

export default NewsPage;

export const pageQuery = graphql`
  query {
    news: allWpNews(
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        title
        date
        content
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
